import { getRating } from '@/helpers/statusIncident.js';
import AreaSelect from '@/components/incidents/elements/AreaSelect/AreaSelect.vue';
import { mapActions, mapState } from 'vuex';
import { validateFileSize, validExtension } from '@/helpers/validationList';
import AssetBox from '@/components/incidents/elements/AssetBox/AssetBox.vue';
import { $EventBus } from '@/main';
import DragDropFile from '@/components/global/DragDropFile.vue';

export default {
	name: 'IncidentCreate',
	data() {
		return {
			breadcrumbs: [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Incidentes',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'IncidentList',
					},
				},
				{
					text: 'Registro de Ticket',
					link: true,
					exact: true,
					disabled: true,
				},
			],
			requireRule: [(v) => !!v || 'Campo requerido'],
			fileRule: [
				(v) => !!v || 'Campo requerido',
				(v) =>
					validExtension(v, [
						'.png',
						'.jpg',
						'.jpeg',
						'.pdf',
						'.xslx',
						'.xlsx',
						'.doc',
						'.docx'
					]) || 'La extensión del archivo no es válida',
				(v) =>
					validateFileSize(v, 10) ||
					'"El archivo es demasiado grande. Máximo 10 MB',
			],
			incidentForm: {
				user: null,
				description: null,
				files: [],
				asset: null,
				flag_observe_asset: false,
				confirm_user: false
			},
			loading: false,
			valid: null,
			assets: [],
			isLoadingAssets: false,
		};
	},

	created() {
		if (this.hasUserSelectPermission) {
			this.getUsersActive();
		} else {
			this.getAssets();
		}
	},

	computed: {
		...mapState('security', ['usersActive']),
		...mapState('auth', ['user']),
		...mapState('subtype', ['selectedSubtype', 'flagObserveAsset', 'flagRequiredAsset', 'classificationSubtype', 'selectedSubtypeObject']),

		hasUserSelectPermission() {
			return this.$store.getters['auth/hasActivity'](
				'incidentes',
				'creacionIncidente',
				'seleccionarUsuario'
			);
		},

		userLocation() {
			if (this.incidentForm.user) {
				const user = this.usersActive.find(user => user.id.toString() === this.incidentForm.user.toString());
				return user;
			} else {
				return null;
			}
		}
	},

	methods: {
		...mapActions('security', ['getUsersActive']),
		...mapActions('incident', ['createIncident']),
		...mapActions('asset', ['getAssetByUser']),

		getRating,

		sendForm() {
			let isvalidated = this.$refs.incidentForm.validate();
			if (isvalidated) {
				this.$swal({
					text: '¿Estás seguro de enviar el incidente?',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#0039a6',
					cancelButtonColor: 'grey',
					cancelButtonText: 'Cancelar',
					confirmButtonText: 'Sí, enviarlo',
				}).then((result) => {
					if (result.isConfirmed) {
						this.registerIncident();
					}
				});
			}
		},

		alertSuccess() {
			this.$swal({
				title: '¡Ticket Enviado!',
				text: 'Te notificaremos por correo cuando tengamos una respuesta. ¡Agradecemos tu paciencia!',
				icon: 'success',
				confirmButtonColor: '#0039a6',
			});
		},

		async registerIncident() {
			this.loading = true;
			let formData = new FormData();

			formData.append('subtype', this.selectedSubtype);

			formData.append(
				'assigned_user_id',
				this.hasUserSelectPermission ? this.incidentForm?.user : this.user?.id
			);

			formData.append('description', this.incidentForm?.description);

			if (this.incidentForm?.asset) formData.append('asset', parseInt(this.incidentForm?.asset));

			formData.append('flag_observe_asset', this.incidentForm?.flag_observe_asset);

			if (Array.isArray(this.incidentForm?.files)) {
				this.incidentForm?.files.forEach((file) => {
					formData.append('ticket_evidence', file, file?.name);
				});
			}

			if (this.incidentForm?.confirm_user) formData.append('confirm_user', this.incidentForm.confirm_user)
			
			if (this.selectedSubtypeObject?.flag_file && (!this.incidentForm.files?.length)) {
				this.loading = false;
				return $EventBus.$emit('showSnack', 'error', 'Este ticket requiere evidencia debido a la configuración de subtipo.');
			}

			let { ok, response, error } = await this.createIncident(formData);
			if (ok) {
				this.alertSuccess();
				if (response?.id) {
					this.$router.push({
						name: 'DetailTicketIncident',
						params: { incidentId: response?.id },
					});
				} else {
					this.$router.push({ name: 'IncidentList' });
				}
			} else {
				let message = error?.data?.message;
				message = Array.isArray(message) ? message.join(', ') : message;
				$EventBus.$emit('showSnack', 'error', message);
			}
			this.loading = false;
		},

		changeUser() {
			this.incidentForm.asset = null;
			this.getAssets();
		},

		async getAssets() {
			this.isLoadingAssets = true;
			this.assets = [];

			try {
				const data = {
					pagination: false,
					assigned_user: this.incidentForm.user || this.user?.id,
					company_id: parseInt(localStorage.getItem('company_id')),
				};
				const { ok, response } = await this.getAssetByUser(data);
				if (ok) {

					const assetResponse = response?.assets.map(asset => {
						return {
							...asset,
							...{ format_name: asset.serial_number && asset.equipment_model ? `${asset.serial_number} - ${asset.equipment_model}` : `${asset.serial_number}` }
						}
					})

					this.assets = assetResponse || [];
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.isLoadingAssets = false;
			}
		},

		customFilter(item, queryText, itemText) {
			const searchTerms = queryText.toLowerCase().split(' ');
			const fullName = itemText.toLowerCase();

			return searchTerms.every(term => fullName.includes(term));
		},
	},
	components: {
		AreaSelect,
		AssetBox,
		DragDropFile
	},
};
